import React from 'react';
import classes from './ContentRow.module.scss'

const ContentRow = ( {title, value, type, children, style={} } ) => {

    const classNames = [
        classes.ContentRow__card
    ];

    return (
        <div className={classes.ContentRow}>
             <div className={classes.ContentRow__fieldValueWrap}>
                 <strong>{title}</strong>
             </div>
            {
                type === 'SlideRanger' &&
                classNames.push(classes.SlideRanger)
            }
             <div className={classNames.join(' ')}  style={style}>
                 {children}
             </div>
         </div>
    )
};

export default ContentRow;