import React, { useEffect, useState } from 'react';
import ContentRow from "../ContentRow/ContentRow";
import { connect } from "react-redux";
import Radio from "../UI/Radio/Radio";
import { setRadioValue } from "../../store/actions/aboutYou";
import Child from "./Child/Child";
import Error from "../Error/Error";
import { validateSelection } from "../../validation/validation";

const Children = ( props ) => {

    const [checked, setChecked] = useState('')
    const [error, setError] = useState(false)

    const radioButtons = [1,2,3,4,5,6]

    const titles = {
        sectionChildrenTitle: 'I have children',
    }

    const onChangeChildren = (e) => {
        setChecked(e.target.value)
        props.setRadio(e)
    }

    useEffect(() => {
        setChecked(props.children.count)
        setError(validateSelection('input[name="children"]'))
    }, [props.children.count, error])


    const childrenNum = ()=> {
        if (props.children.count > 0 ) {
            const children = []
            for(let i=0; i< props.children.count; i++) {
                children[i] = i+1
            }

            return children
        }
        return []
    }

    const childrenYearsSliders = childrenNum().map((child, index) => {
        return (
            <Child key={index} num={child} />
        )
    })
    

    const children = radioButtons.map((item) => {
        return (
                <Radio
                    key={item.toString()}
                    type={'quantity'}
                    name={"children"}
                    value={item}
                    isSelected={ parseInt(checked) === item }
                    changed={onChangeChildren}
                />
        )
    })

    const childrenWord = (props.children.count === '1') ? ' child' : ' children'

    return (
        <React.Fragment>
            <ContentRow
                title={titles.sectionChildrenTitle}
                value={[(props.children.count) + childrenWord]}
            >
                {
                    error &&
                    <Error message='Selection required'/>
                }
                {children}
             </ContentRow>
            {
                props.children.count > 0 &&
                childrenYearsSliders
            }
        </React.Fragment>
    )
};

function mapStateToProps(state) {
    return {
        children: state.aboutYou.myFamily.childrenData.children,
    }
}


function mapDispatchToProps(dispatch) {
    return {
        setRadio : event => dispatch(setRadioValue(event)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Children);