import React, { useEffect, useState } from 'react';
import ContentRow from "../../ContentRow";
import classes from "../../../Children/Child/Child.module.scss";
import YearsOld from "../../YearsOld/YearsOld";
import { setDependantYearsOld } from "../../../../store/actions/aboutYou";
import { connect } from "react-redux";

const Dependant = ( props ) => {

    const startVal = props.dependants.years[props.num-1] ? props.dependants.years[props.num-1] : 13
    const [ value, setValue ] = useState(startVal);

    const setDependantYearsOldHandler = (val) => {
        setValue(val)
        props.setDependantYearsOld(props.num-1, val)
    }

    useEffect(()=>{
        setValue(startVal)
    }, [startVal])

return (
    <ContentRow
        title=''
        value={[value+' years old']}
    >
            <strong className={classes.childSubtitle}>Dependant {props.num}</strong>
                <YearsOld
                    style={{alignSelf: 'flex-start'}}
                    min={0}
                    max={99}
                    startVal={startVal}
                    onChange={setDependantYearsOldHandler}
                />
             </ContentRow>
)
};

function mapStateToProps(state) {
    return {
        dependants: state.aboutYou.myFamily.dependantsData.dependants
    }
}


function mapDispatchToProps(dispatch) {
    return {
        setDependantYearsOld: (key, val) => dispatch(setDependantYearsOld(key, val)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dependant);