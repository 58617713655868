import React, { useEffect } from 'react';
import Layout from "../../../../hoc/Layout/Layout";
import ContentRow from "../../../../components/ContentRow/ContentRow";
import YearsOld from "../../../../components/ContentRow/YearsOld/YearsOld";
import {connect} from 'react-redux'
import MyFamily from "../../../../components/ContentRow/Step1/MyFamily/MyFamily";
import Navigation from "../../../../components/Navigation/Navigation";
import Gender from "../../../../components/ContentRow/Gender/Gender";
import {
    setAboutYouSumCompleted,
    setIAmYearsOldHandler,
    setRadioValue,
    setSpouseYearsOldHandler, validationHandler
} from "../../../../store/actions/aboutYou";
import Children from "../../../../components/Children/Children";
import Dependants from "../../../../components/ContentRow/Step1/Dependants/Dependants";
import {Helmet} from "react-helmet";
import { retirementCalcLinks } from "../../../../Routes/RoutesConst";

const AboutYou = (props) => {

    const titles = {
        layoutTitle: 'TELL US ABOUT YOURSELF',
        sectionTitle: 'We need to know a bit about you and your family.',
        sectionIAmTitle: 'I am',
        sectionMyFamilyTitle: 'My family includes',
        sectionMySpouseTitle: 'My spouse is',
        sectionChildrenTitle: 'I have',
    };

    const myFamilyValues = [];

    if (props.me) {
        myFamilyValues.push('Just Me')
    }
    if (props.isSpouse) {
        myFamilyValues.push('Spouse')
    }
    if (props.isChildren) {
        myFamilyValues.push('Children')
    }
    if (props.isDependants) {
        myFamilyValues.push('Dependants')
    }

    useEffect(() => {
        const error = false;
        props.setAboutYouSumCompleted(!error);
        props.validationHandler()
    }, [props]);


 return (
     <Layout
         title={titles.layoutTitle}
         progressStep1={(1/7)*100}
         active={'step1'}
     >
         <Helmet>
            <title>Tell us about your self</title>
        </Helmet>
         <h2>{titles.sectionTitle}</h2>

         <ContentRow
             title={titles.sectionIAmTitle}
             value={[props.iAm]}
             //style={{justifyContent: 'space-between'}}
         >
             <Gender
                 name='iAmGender'
                 onChange={props.setRadio}
                 value={props.iAm}
             />
         </ContentRow>

         {
             props.iAm !== '' &&
                 <React.Fragment>
                     <ContentRow
                         title='Age'
                         value={[props.iAmYearsOld + ' years old']}
                     >
                         <YearsOld
                             min={18}
                             max={71}
                             startVal={props.iAmYearsOld}
                             onChange={props.setIAmYearsOld}
                         />
                     </ContentRow>
                     <ContentRow
                         title={titles.sectionMyFamilyTitle}
                         value={myFamilyValues}
                     >
                        <MyFamily/>
                     </ContentRow>
                 </React.Fragment>
         }

         {
             props.isSpouse &&
                 <ContentRow
                     title={titles.sectionMySpouseTitle}
                     value={[props.sexSpouse]}
                 >
                     <Gender
                         name="sexSpouse"
                         onChange={props.setRadio}
                         value={props.sexSpouse}
                     />
                 </ContentRow>
         }

         {
             props.isSpouse && props.sexSpouse &&
             <ContentRow
                 title=''
                 value={[props.spouseYearsOld + ' years old']}
             >
                 <YearsOld
                     min={18}
                     max={71}
                     startVal={props.spouseYearsOld}
                     onChange={props.setSpouseYearsOld}
                 />
             </ContentRow>
         }

         {
             props.isChildren &&
             <Children/>
         }

         {
             props.isDependants &&
             <Dependants/>
         }

         <Navigation
             isCompleted={props.isCompleted}
             next={retirementCalcLinks.retireAge}
             additionalClass='mainNavWrap'
         />

     </Layout>
 )
};

function mapStateToProps(state) {
    return {
        iAm: state.aboutYou.iAm,
        iAmYearsOld: state.aboutYou.iAmYearsOld,
        isSpouse: state.aboutYou.myFamily.spouseData.isSpouse,
        me: state.aboutYou.myFamily.me,
        sexSpouse: state.aboutYou.myFamily.spouseData.gender,
        spouseYearsOld: state.aboutYou.myFamily.spouseData.yearsOld,
        isChildren: state.aboutYou.myFamily.childrenData.isChildren,
        isDependants: state.aboutYou.myFamily.dependantsData.isDependants,
        isCompleted: state.aboutYou.isCompleted,
    }
}


function mapDispatchToProps(dispatch) {
    return {
        setRadio : event => dispatch(setRadioValue(event)),
        setIAmYearsOld: val => dispatch(setIAmYearsOldHandler(val)),
        setSpouseYearsOld: val => dispatch(setSpouseYearsOldHandler(val)),
        setAboutYouSumCompleted: val => dispatch(setAboutYouSumCompleted(val)),
        validationHandler: val => dispatch(validationHandler()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AboutYou);