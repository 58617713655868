import React, { useEffect, useState } from 'react';
import Checkbox from "../../../UI/Checkbox/Checkbox";
import { connect } from "react-redux";
import { updateMyFamilyData } from "../../../../store/actions/aboutYou";
import { validateSelection } from "../../../../validation/validation";
import Error from "../../../Error/Error";


const MyFamily = ( props ) => {

    const [error, setError] = useState(false)

    const checkboxes = [
        {
            label: `Just Me`,
            name: 'justMe'
        },
        {
            label: `Spouse`,
            name: 'spouse'
        },
        {
            label: `Children`,
            name: 'isChildren'
        },
        {
            label: `Dependents`,
            name: 'isDependants'
        }
    ]

    const initState = {
        checkedItems: new Map(),
    }

    const [checked, setChecked] = useState(initState)

    const changeValHandler = (e) => {

        const item = e.target.name;
        const isChecked = e.target.checked;

        if (item === 'justMe' ) {
            checked.checkedItems.set('justMe', true)
            checked.checkedItems.set('spouse', false)
            checked.checkedItems.set('isChildren', false)
            checked.checkedItems.set('isDependants', false)

            setChecked({ checkedItems: checked.checkedItems })
        }else {
            checked.checkedItems.set('justMe', false)
            setChecked({ checkedItems: checked.checkedItems.set(item, isChecked) })
        }

        props.updateMyFamilyData(checked.checkedItems)
    }

    useEffect(() => {
        if (props.me) {
            checked.checkedItems.set('justMe', true)
        }

        if (props.spouse) {
            checked.checkedItems.set('spouse', true)
        }

        if (props.children) {
           checked.checkedItems.set('isChildren', true)
        }

        if (props.dependants) {
            checked.checkedItems.set('isDependants', true)
        }

        setChecked({ checkedItems: checked.checkedItems })
        setError(validateSelection('input[name="justMe"], input[name="spouse"], input[name="isChildren"], input[name="isDependants"]'))

    },[checked.checkedItems, props.children, props.dependants, props.me, props.spouse, error])

    return (
        <React.Fragment>
            {
                error &&
                <Error message='Selection required'/>
            }
            {
                checkboxes.map((input, index) => {
                    return (
                        <Checkbox
                            key={index}
                            onChange={changeValHandler}
                            label={input.label}
                            name={input.name}
                            checked={ checked.checkedItems.get(input.name) }
                        />
                        )
                })
            }
        </React.Fragment>
    )
};

function mapStateToProps(state) {
    return {
        me: state.aboutYou.myFamily.me,
        spouse: state.aboutYou.myFamily.spouseData.isSpouse,
        children: state.aboutYou.myFamily.childrenData.isChildren,
        dependants: state.aboutYou.myFamily.dependantsData.isDependants
    }
}

function mapDispatchToProps(dispatch) {
    return {
        updateMyFamilyData : checkedItems => dispatch(updateMyFamilyData(checkedItems))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyFamily);