import React, { useEffect, useState } from 'react';
import Radio from "../../UI/Radio/Radio";
import Error from "../../Error/Error";
import {validateSelection} from "../../../validation/validation";


const Gender = ( props ) => {
    const [checked, setChecked] = useState('')
    const [error, setError] = useState(false)

    const radios = [
        {
            name: props.name,
            value: 'Male'
        },
        {
            name: props.name,
            value: 'Female'
        }
    ]

    const changeValHandler = (e) => {
        setChecked(e.target.value)
        props.onChange(e)
    }

    const selector = props.name === 'sexSpouse' ? 'input[name="sexSpouse"]' : 'input[name="iAmGender"]'

    useEffect(() => {
        setChecked(props.value)
        setError(validateSelection(selector))
    }, [props.value, error, selector])

    return (
        <React.Fragment>
            {
                error &&
                <Error
                    message='Selection required'
                />
            }

            {
                radios.map((radio, index) => {
                    return (
                             <Radio
                                 key={index}
                                 changed={changeValHandler}
                                 name={radio.name}
                                 value={radio.value}
                                 isSelected={ checked === radio.value }
                             />
                    )
                })
            }
        </React.Fragment>
    )
};

export default Gender;