import React, {useState} from 'react';
import classes from './YearsOld.module.scss'
import 'react-rangeslider/lib/index.css'
import './Slider.scss'
import Slider from 'react-rangeslider'

const YearsOld = ( props ) => {
    const [ value, setValue ] = useState(props.startVal ? props.startVal : 0);
    const horizontalLabels = {
        0: `${props.min} years`,
        100: `${props.max} years`
    }
    //const classVal = props.title

    const onChangeRangeHandler = (value) => {
        props.onChange(value)
        setValue(value)
    }

    const style = props.style ? props.style : {}

    return (
        <div className={classes.SliderWrap} style={style}>
            <div >{props.title}</div>
            <div className='slider custom-labels'>
            <Slider
                min={props.min}
                max={props.max}
                value={value}
                tooltip={false}
                handleLabel={value.toString()}
                labels={horizontalLabels}
                onChange={value => onChangeRangeHandler(value)}
            />
            </div>
        </div>
    );
};

export default YearsOld;