import React, { useEffect, useState } from 'react';
import Radio from "../../../UI/Radio/Radio";
import ContentRow from "../../ContentRow";
import { setRadioValue } from "../../../../store/actions/aboutYou";
import { connect } from "react-redux";
import Dependant from "./Dependant";
import { validateSelection } from "../../../../validation/validation";
import Error from "../../../Error/Error";

const Dependants = ( props ) => {
    const [checked, setDependants] = useState('')
    const [error, setError] = useState(false)

    const radioButtons = [1,2,3,4,5,6]

    const titles = {
        sectionDependantsTitle: 'I have...',
    }

    const onChangeDependants = (e) => {
        setDependants(e.target.value)
        props.setRadio(e)
    }

    useEffect(() => {
        setDependants(props.dependants.count)
        setError(validateSelection('input[name="dependants"]'))
    }, [props.dependants.count, error])


    const dependantsNum = ()=> {
        if (props.dependants.count > 0 ) {
            const dependants = []
            for(let i=0; i< props.dependants.count; i++) {
                dependants[i] = i+1
            }

            return dependants
        }
        return []
    }

    const dependantsYearsSliders = dependantsNum().map((child, index) => {
        return (
            <Dependant key={index} num={child} />
        )
    })


    const dependant = radioButtons.map((item) => {
        return (
                <Radio
                    key={item.toString()}
                    name={"dependants"}
                    type='quantity'
                    value={item}
                    isSelected={ parseInt(checked) === item }
                    changed={onChangeDependants}
                />
        )
    })

    const dependantsWord = (props.dependants.count === '1') ? ' dependant' : ' dependants'

    return (
        <React.Fragment>
            <ContentRow
                title={titles.sectionDependantsTitle}
                value={[(props.dependants.count) + dependantsWord]}
            >
                {
                    error &&
                    <Error message='Selection required'/>
                }
                {dependant}
             </ContentRow>
            {
                props.dependants.count > 0 &&
                dependantsYearsSliders
            }
        </React.Fragment>
    )
};

function mapStateToProps(state) {
    return {
        dependants: state.aboutYou.myFamily.dependantsData.dependants,
    }
}


function mapDispatchToProps(dispatch) {
    return {
        setRadio : event => dispatch(setRadioValue(event)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dependants);