import React, { useEffect, useState } from 'react';
import YearsOld from "../../ContentRow/YearsOld/YearsOld";
import ContentRow from "../../ContentRow/ContentRow";
import {setChildYearsOld } from "../../../store/actions/aboutYou";
import { connect } from "react-redux";
import classes from './Child.module.scss'

const Child = ( props ) => {

    const startVal = props.children.years[props.num-1] ? props.children.years[props.num-1] : 13
    const [ value, setValue ] = useState(startVal);

    const setChildYearsOldHandler = (val) => {
        setValue(val)
        props.setChildYearsOld(props.num-1, val)
    }

    useEffect(()=>{
        setValue(startVal)
    }, [startVal])

    return (
        <ContentRow
            title=''
            value={[value+' years old']}
        >
            <strong className={classes.childSubtitle}>Child {props.num}</strong>
                <YearsOld
                    style={{alignSelf: 'flex-start'}}
                    min={0}
                    max={26}
                    startVal={startVal}
                    onChange={setChildYearsOldHandler}
                />
             </ContentRow>
    )
};

function mapStateToProps(state) {
    return {
        children: state.aboutYou.myFamily.childrenData.children,
    }
}


function mapDispatchToProps(dispatch) {
    return {
        setChildYearsOld: (key, val) => dispatch(setChildYearsOld(key, val)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Child);